import { FieldProps } from "formik";
import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";

/**
 * Implements expected error and validation behaviors that is not present on
 * the Formik-MUI KeyboardDatePicker
 *
 * @param param0
 */
const DatePicker = ({ field, form, ...other }: FieldProps) => {
  const currentError = form.errors[field.name];

  return (
    <KeyboardDatePicker
      clearable
      disablePast
      name={field.name}
      value={field.value}
      helperText={currentError}
      error={Boolean(form.errors[field.name])}
      onChange={(date) => form.setFieldValue(field.name, date, false)}
      onBlur={() => form.validateField(field.name)}
      {...other}
    />
  );
};

export default DatePicker;
