import React, { FC, useMemo } from "react";
import { TableCell } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../redux/config.redux";
import { useTranslation } from "react-i18next";
import { Recurring, RecurringState } from "../../../models/Recurring.model";
import moment from "moment";
import { Atoms } from "../../../atoms/Atoms";

interface Props {
  classes: Record<any, string>;
  recurring: Recurring;
}

const RecurringListItem: FC<Props> = ({ recurring, classes }) => {
  const local = useSelector(selectLanguage) as string;
  const { t } = useTranslation();

  const amount = useMemo(
    () =>
      new Intl.NumberFormat(local, {
        style: "currency",
        currency: recurring.currency.toString(),
      }).format(recurring.amountPerInterval),
    [recurring, local]
  );

  const recurringState = useMemo((): RecurringState => {
    const startMoment = moment(recurring.startDate);
    const todayMoment = moment();
    if (startMoment.isAfter(moment())) {
      return "STARTING";
    }
    if (!recurring.endDate) {
      return "INDEFINITELY";
    }
    const endDateMoment = moment(recurring.endDate);
    if (endDateMoment.isBefore(todayMoment)) {
      return "CLOSED";
    }
    return "RUNNING";
  }, [recurring]);

  const startDateMoment = useMemo(() => moment(recurring.startDate), [
    recurring,
  ]);
  const endDateMoment = useMemo(() => moment(recurring.endDate), [recurring]);

  return (
    <>
      <TableCell>
        {t(`recurring.categories.${recurring.category?.toLowerCase()}`) || ""}
      </TableCell>
      <TableCell>{recurring.description}</TableCell>
      <TableCell>
        <Atoms.StyledAmount amount={recurring.amountPerInterval}>
          {amount}
          {" ("}
          {t(`recurring.intervals.${recurring.interval.toLowerCase()}`)}
          {")"}
        </Atoms.StyledAmount>
      </TableCell>
      <TableCell>
        {t(`recurring.states.${recurringState.toLowerCase()}`, {
          startDate: startDateMoment,
          endDate: endDateMoment,
        })}
      </TableCell>
      <TableCell className={classes.actionItems}></TableCell>
    </>
  );
};

export default RecurringListItem;
