import {
  createAction,
  chainReducers,
  onAction,
  withInitialState,
} from "redux-preboiled";
import { CognitoUserSession } from "amazon-cognito-identity-js";

//
// State
//

export interface AuthState {
  authInProgress: boolean;
  cognitoUsername: string | null;
  isLoggedIn: boolean;
  accessTokenJwt: string | null;
  accessTokenExp: number | null;
  accessTokenUsername: string | null;
  refreshToken: string | null;
  isRefreshingToken: boolean;
  loginErrorMsg: string | null;
  newPasswordErrorMsg: string | null;
}

interface State {
  auth: AuthState;
}

//
// Cognito User
//

export const saveCognitoUsername = createAction(
  "auth/saveCognitoUsername"
).withPayload<{ username: string }>();

//
// Login
//

export const loginAction = createAction("auth/login");

export const loginSucceededAction = createAction(
  "auth/loginSucceeded"
).withPayload<{ session: CognitoUserSession }>();

export const loginFailedAction = createAction("auth/loginFailed").withPayload<{
  err: string;
}>();

//
// Logout
//

export const loggedOutAction = createAction("auth/loggedOut");

//
// Password Reset
//

export const newPasswordRequiredAction = createAction(
  "auth/newPasswordRequired"
);

export const settingNewPasswordAction = createAction("auth/setNewPassword");

export const setNewPasswordSucceededAction = createAction(
  "auth/setNewPasswordSucceeded"
).withPayload<{ session: CognitoUserSession }>();

export const setNewPasswordFailedAction = createAction(
  "auth/setNewPasswordFailed"
).withPayload<{ err: string }>();

export const newPasswordCanceledAction = createAction(
  "auth/newPasswordCanceled"
);

//
// Session Refresh
//

export const startedTokenRefresh = createAction("auth/startedTokenRefresh");

export const successfulTokenRefresh = createAction(
  "auth/successfulTokenRefresh"
).withPayload<{ session: CognitoUserSession }>();

export const failedTokenRefresh = createAction("auth/failedTokenRefresh");

//
// Errors
//

export const clearErrorMessagesAction = createAction("auth/clearErrorMessages");

//
// Selectors
//

export const selectIsAuthInProgress = (state: State) => {
  return state.auth.authInProgress;
};

export const selectUsername = (state: State) => {
  return state.auth.cognitoUsername;
};

export const selectIsLoggedIn = (state: State) => {
  return state.auth.isLoggedIn;
};

export const selectLoginError = (state: State) => {
  return state.auth.loginErrorMsg;
};

export const selectNewPassworError = (state: State) => {
  return state.auth.newPasswordErrorMsg;
};

export const selectAccessTokenJwt = (state: State) => {
  return state.auth.accessTokenJwt;
};

export const selectAccessTokenExpiration = (state: State) => {
  return state.auth.accessTokenExp;
};

export const selectIsRefreshingToken = (state: State) => {
  return state.auth.isRefreshingToken;
};

export const selectRefreshToken = (state: State) => {
  return state.auth.refreshToken;
};

export const selectCognitoUsername = (state: State) => {
  return state.auth.cognitoUsername;
};

export const selectIsAccessTokenValid = (state: State) => {
  const timeInSeconds = Date.now() / 1000;
  const tokenExpiration = selectAccessTokenExpiration(state);
  return (
    selectAccessTokenJwt(state) &&
    tokenExpiration &&
    tokenExpiration > timeInSeconds
  );
};

const initialState: AuthState = {
  authInProgress: false,
  cognitoUsername: null,
  isLoggedIn: false,
  accessTokenJwt: null,
  accessTokenExp: null,
  accessTokenUsername: null,
  refreshToken: null,
  isRefreshingToken: false,
  loginErrorMsg: null,
  newPasswordErrorMsg: null,
};

//
// Reducers
//

export default chainReducers(
  withInitialState(initialState),
  onAction(saveCognitoUsername, (state, { payload: { username } }) => ({
    ...state,
    cognitoUsername: username,
  })),
  onAction(loginAction, (state) => ({ ...state, authInProgress: true })),
  onAction(loginSucceededAction, (state, { payload: { session } }) => ({
    ...state,
    isLoggedIn: true,
    accessTokenJwt: session.getAccessToken().getJwtToken(),
    accessTokenExp: session.getAccessToken().getExpiration(),
    accessTokenUsername: session.getAccessToken().payload.username,
    refreshToken: session.getRefreshToken().getToken(),
    loginErrorMsg: null,
    newPasswordErrorMsg: null,
    authInProgress: false,
  })),
  onAction(loginFailedAction, (state, { payload: { err } }) => ({
    ...state,
    authInProgress: false,
    loginErrorMsg: err,
  })),
  onAction(loggedOutAction, () => ({ ...initialState })),
  onAction(startedTokenRefresh, (state) => ({
    ...state,
    isRefreshingToken: true,
  })),
  onAction(newPasswordRequiredAction, (state) => ({
    ...state,
    authInProgress: false,
  })),
  onAction(newPasswordCanceledAction, () => ({
    ...initialState,
  })),
  onAction(successfulTokenRefresh, (state, { payload: { session } }) => {
    return {
      ...state,
      isLoggedIn: true,
      accessTokenJwt: session.getAccessToken().getJwtToken(),
      accessTokenExp: session.getAccessToken().getExpiration(),
      accessTokenUsername: session.getAccessToken().payload.username,
      refreshToken: session.getRefreshToken().getToken(),
      loginErrorMsg: null,
      newPasswordErrorMsg: null,
      authInProgress: false,
      isRefreshingToken: false,
    };
  }),
  onAction(failedTokenRefresh, () => ({ ...initialState })),
  onAction(settingNewPasswordAction, (state) => ({
    ...state,
    authInProgress: true,
  })),
  onAction(
    setNewPasswordSucceededAction,
    (state, { payload: { session } }) => ({
      ...state,
      isLoggedIn: true,
      accessTokenJwt: session.getAccessToken().getJwtToken(),
      accessTokenExp: session.getAccessToken().getExpiration(),
      accessTokenUsername: session.getAccessToken().payload.username,
      refreshToken: session.getRefreshToken().getToken(),
      loginErrorMsg: null,
      newPasswordErrorMsg: null,
      authInProgress: false,
    })
  ),
  onAction(setNewPasswordFailedAction, (state, { payload: { err } }) => ({
    ...state,
    newPasswordErrorMsg: err,
    authInProgress: false,
  })),
  onAction(clearErrorMessagesAction, (state) => ({
    ...state,
    newPasswordErrorMsg: null,
    loginErrorMsg: null,
  }))
);
