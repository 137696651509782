import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import RecurringListItem from "./RecurringListItem";
import { Recurring } from "../../../models/Recurring.model";
import RecurringListEmptyItem from "./RecurringListEmptyItem";
import RecurringListSkeleton from "./RecurringListSkeleton";
import { selectIsGettingRecurring } from "../recurring.redux";
import { useSelector } from "react-redux";

interface Props {
  classes: Record<any, string>;
  recurring: Recurring[];
}

const RecurringList: FC<Props> = ({ classes, recurring }) => {
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsGettingRecurring);

  return (
    <>
      <TableContainer>
        <Table aria-label="List of recurring">
          <TableHead>
            <TableRow key={"header"}>
              <TableCell>{t("recurring.list.header.category")}</TableCell>
              <TableCell>{t("recurring.list.header.description")}</TableCell>
              <TableCell>{t("recurring.list.header.amount")}</TableCell>
              <TableCell>{t("recurring.list.header.status")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              recurring.map((recurringItem) => (
                <TableRow
                  key={recurringItem.id}
                  hover
                  classes={{ hover: classes.hover }}
                >
                  <RecurringListItem
                    recurring={recurringItem}
                    classes={classes}
                  ></RecurringListItem>
                </TableRow>
              ))}
            {!isLoading && recurring.length === 0 && <RecurringListEmptyItem />}
            {isLoading && <RecurringListSkeleton />}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RecurringList;
