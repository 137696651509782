import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  selectCognitoUsername,
  saveCognitoUsername,
  loginAction,
  newPasswordRequiredAction,
  loginSucceededAction,
  loginFailedAction,
  settingNewPasswordAction,
  loggedOutAction,
  setNewPasswordSucceededAction,
  setNewPasswordFailedAction,
} from "../containers/auth/auth.redux";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { userPool } from "../Environment";
import { shouldKeepRouteAtLogin, Route } from "../routes";
import { AuthUser } from "../models/Auth.model";
import {
  AuthService,
  LoginResponseType,
} from "../containers/auth/auth.service";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const useLogout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const cognitoUsername = useSelector(selectCognitoUsername);
  const snackbar = useSnackbar();

  return useCallback(
    (path?: string) => {
      const user = new CognitoUser({
        Username: cognitoUsername as string,
        Pool: userPool,
      });
      user.signOut();
      dispatch(loggedOutAction());
      history.push(path || Route.ROOT);
      snackbar.enqueueSnackbar(t("notification.auth.logoutSuccess"), {
        variant: "success",
      });
    },
    [history, dispatch, cognitoUsername, snackbar, t]
  );
};

export const useLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const snackbar = useSnackbar();

  return useCallback(
    ({ email, password }: AuthUser) => {
      dispatch(saveCognitoUsername({ username: email }));

      const user = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      dispatch(loginAction());

      AuthService.loginWithCredentials(user, authDetails)
        .then(({ type, session }) => {
          if (type === LoginResponseType.NEW_PASSWORD_REQUIRED)
            return dispatch(newPasswordRequiredAction());
          if (!shouldKeepRouteAtLogin(location.pathname))
            history.push(Route.ROOT);

          snackbar.enqueueSnackbar(t("notification.auth.loginSuccess"), {
            variant: "success",
          });

          return dispatch(
            loginSucceededAction({ session: session as CognitoUserSession })
          );
        })
        .catch((err) => {
          return dispatch(
            loginFailedAction({
              err: t("auth.login.errors.badCredentials"),
            })
          );
        });
    },
    [dispatch, history, location, snackbar, t]
  );
};

export const useUpdatePassword = () => {
  const { t } = useTranslation();
  const cognitoUsername = useSelector(selectCognitoUsername) as string;
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  return useCallback(
    (oldPassword: string, newPassword: string) => {
      let user = new CognitoUser({
        Username: cognitoUsername as string,
        Pool: userPool,
      });
      dispatch(settingNewPasswordAction());

      let authDetails = new AuthenticationDetails({
        Username: cognitoUsername,
        Password: oldPassword,
      });

      AuthService.loginWithCredentials(user, authDetails, newPassword)
        .then(({ session }) => {
          snackbar.enqueueSnackbar(t("notification.auth.newPasswordSuccess"), {
            variant: "success",
          });
          dispatch(
            setNewPasswordSucceededAction({
              session: session as CognitoUserSession,
            })
          );
        })
        .catch((err) => {
          dispatch(
            setNewPasswordFailedAction({
              err:
                err.code === "NotAuthorizedException"
                  ? t("auth.newPassword.errors.wrongPassword")
                  : t("auth.newPassword.errors.failed"),
            })
          );
        });
    },
    [dispatch, cognitoUsername, snackbar, t]
  );
};
