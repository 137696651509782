import {
  Theme,
  createStyles,
  CircularProgress as CircularProgressBase,
  makeStyles,
} from "@material-ui/core";
import { FC } from "react";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    progressContainer: {
      position: "relative",
    },
    progress: {
      position: "absolute",
      left: theme.spacing(1),
    },
  });

const makeClasses = makeStyles(styles);

const CustomCircularProgress: FC = () => {
  const classes = makeClasses();

  return (
    <span className={classes.progressContainer}>
      <CircularProgressBase className={classes.progress} size="1em" />
    </span>
  );
};

export default CustomCircularProgress;
