import React, { FC, useCallback, useMemo, SyntheticEvent } from "react";
import {
  RecurringCategory,
  RecurringCategories,
} from "../../models/Category.model";
import { RecurringCreationForm } from "../../models/Recurring.model";
import { Currency } from "../../models/Currency.enum";
import { Interval, Intervals } from "../../models/Interval.enum";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, FormikHelpers } from "formik";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  InputAdornment,
  LinearProgress,
} from "@material-ui/core";
import IncomeSwitchInput from "../components/inputs/incomeSwitchInput/IncomeSwitchInput";
import AmountInput from "../components/inputs/amountInput/AmountInput";
import { Select, CheckboxWithLabel, TextField } from "formik-material-ui";
import moment from "moment";
import Yup from "../../utils/validation";
import DatePicker from "../components/inputs/datePicker/DatePicker";
import CreateIcon from "@material-ui/icons/Create";
import { useRecurringService } from "../../containers/recurring/recurring.service";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { createdRecurringAction } from "../../containers/recurring/recurring.redux";
import CategorySelect from "../components/inputs/categorySelect/CategorySelect";

type Props = {
  classes: Record<any, string>;
};

const initialValues: RecurringCreationForm = {
  isIncome: false,
  hasEndDate: false,
  currency: Currency.EUR,
  category: RecurringCategory._TBD,
  startDate: moment().startOf("month"),
  endDate: null,
  interval: Interval.MONTHLY,
  amountPerInterval: "",
  description: "",
};

const CreateRecurringForm: FC<Props> = ({ classes }) => {
  const { t } = useTranslation();
  const { createRecurring } = useRecurringService();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  initialValues.amountPerInterval = t(
    "recurring.createForm.values.initialAmount"
  );

  const toggleEndDate = useCallback(
    (event, values, handleChange, setFieldValue) => {
      var newHasEndDate = !values.hasEndDate;
      handleChange(event);
      if (newHasEndDate) {
        setFieldValue("endDate", new Date(), true);
      } else {
        setFieldValue("endDate", null, false);
      }
    },
    []
  );

  const onSubmit = useCallback(
    (
      values: RecurringCreationForm,
      { setSubmitting, setValues }: FormikHelpers<RecurringCreationForm>
    ) => {
      setSubmitting(true);
      createRecurring(values)
        .then((result) => {
          dispatch(createdRecurringAction({ recurring: result }));
          snackbar.enqueueSnackbar(t("notification.recurring.createdSuccess"), {
            variant: "success",
          });
          setValues({ ...initialValues }, false);
        })
        .catch((_err) => {
          snackbar.enqueueSnackbar(t("notification.generalError.unexpected"), {
            variant: "error",
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [createRecurring, dispatch, snackbar, t]
  );

  const minStartDate = useMemo(
    () => moment().startOf("month").subtract(3, "month").toDate(),
    []
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          amountPerInterval: Yup.string()
            .isLocaleNumber(t("form.error.invalidNumberFormat"))
            .required(t("form.error.amountRequired")),
          startDate: Yup.date()
            .nullable()
            .required(t("form.error.dateRequired")),
          hasEndDate: Yup.bool(),
          description: Yup.string().max(
            128,
            t("form.error.maxLength", { max: 128 })
          ),
          endDate: Yup.date()
            .nullable()
            .when("hasEndDate", {
              is: true,
              then: Yup.date()
                .min(
                  Yup.ref("startDate"),
                  t("form.error.endDateBeforeStartDate")
                )
                .required(t("form.error.dateRequired")),
            }),
        })}
      >
        {({ values, isSubmitting, handleChange, setFieldValue }) => (
          <Form>
            <Grid item>
              <Field
                component={IncomeSwitchInput}
                name="isIncome"
                classes={classes}
              ></Field>
            </Grid>
            <Grid container spacing={3} direction="column">
              <Grid item>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  name="description"
                  label={t("recurring.createForm.labels.description")}
                  disabled={isSubmitting}
                  className={classes.input}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CreateIcon color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={AmountInput}
                    name="amountPerInterval"
                    labelId="form.component.input.amount.labels.recurringAmount"
                    isIncome={values.isIncome}
                  ></Field>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="category">
                      {t("recurring.createForm.labels.category")}
                    </InputLabel>
                    <Field
                      component={CategorySelect}
                      categories={RecurringCategories}
                      name="category"
                      inputProps={{ id: "category-recurring" }}
                    ></Field>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="interval">
                      {t("recurring.createForm.labels.interval")}
                    </InputLabel>
                    <Field
                      component={Select}
                      name="interval"
                      inputProps={{ id: "interval-recurring" }}
                    >
                      {Intervals.map((interval) => (
                        <MenuItem value={interval.value} key={interval.value}>
                          {t(interval.text)}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    fullWidth
                    component={DatePicker}
                    name="startDate"
                    label={t("form.component.input.date.labels.startDate")}
                    disabled={isSubmitting}
                    cancelLabel={t("form.shared.cancel")}
                    okLabel={t("form.shared.ok")}
                    format={t("format.localeDate")}
                    minDate={minStartDate}
                    disablePast={false}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={CheckboxWithLabel}
                    name="hasEndDate"
                    Label={{ label: "Set End-Date" }}
                    type="checkbox"
                    inputProps={{
                      onChange: (e: SyntheticEvent) =>
                        toggleEndDate(e, values, handleChange, setFieldValue),
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    fullWidth
                    component={DatePicker}
                    name="endDate"
                    label={t("form.component.input.date.labels.endDate")}
                    disabled={!values.hasEndDate || isSubmitting}
                    cancelLabel={t("form.shared.cancel")}
                    okLabel={t("form.shared.ok")}
                    format={t("format.localeDate")}
                    minDate={values.startDate}
                  />
                </Grid>
              </Grid>
              <Grid item>
                {isSubmitting && <LinearProgress></LinearProgress>}
              </Grid>
              <Grid item container justify="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateRecurringForm;
