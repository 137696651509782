import React, { FC, useCallback } from "react";
import NavLayout, { Props } from "./components/NavLayout";
import { useDispatch, useSelector } from "react-redux";
import { toggleNavAction, selectShowNavigation } from "./nav.redux";
import { selectIsLoggedIn } from "../auth/auth.redux";
import {
  toggleSignupModalAction,
  toggleLoginModalAction,
} from "../../redux/modals.redux";
import { useLogout } from "../../hooks/auth";

const NavContainer: FC = () => {
  const dispatch = useDispatch();
  const logout = useLogout();

  const handleClose = useCallback(
    () => dispatch(toggleNavAction({ show: false })),
    [dispatch]
  );
  const handleOpen = useCallback(
    () => dispatch(toggleNavAction({ show: true })),
    [dispatch]
  );
  const openSignUp = useCallback(
    () => dispatch(toggleSignupModalAction({ show: true })),
    [dispatch]
  );
  const openLogin = useCallback(
    () => dispatch(toggleLoginModalAction({ show: true })),
    [dispatch]
  );

  const props: Props = {
    handleClose: handleClose,
    handleOpen: handleOpen,
    isLoggedIn: useSelector(selectIsLoggedIn),
    showNav: useSelector(selectShowNavigation),
    openSignUp: openSignUp,
    openLogin: openLogin,
    handleLogout: logout,
  };

  return <NavLayout {...props} />;
};

export default NavContainer;
