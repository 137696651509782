import { Recurring } from "../models/Recurring.model";
import { DaysInInterval } from "../models/Interval.enum";
import { Moment } from "moment";

export const getDailyImpact = ({ amountPerInterval, interval }: Recurring) => {
  return amountPerInterval / DaysInInterval[interval];
};

export const getExactDailyImpact = (
  { amountPerInterval, interval }: Recurring,
  refDate: Moment
) => {
  switch (interval) {
    case "DAILY":
      return amountPerInterval;
    case "WEEKLY":
      return amountPerInterval / 7;
    case "MONTHLY":
      return amountPerInterval / refDate.daysInMonth();
    case "QUATERLY":
      return amountPerInterval / (refDate.isLeapYear() ? 366 / 4 : 365 / 4);
    case "HALF_YEARLY":
      return amountPerInterval / (refDate.isLeapYear() ? 366 / 2 : 365 / 2);
    case "YEARLY":
      return amountPerInterval / (refDate.isLeapYear() ? 366 : 365);
    default:
      return amountPerInterval;
  }
};

export const getAbsDailyImpact = ({
  amountPerInterval,
  interval,
}: Recurring) => {
  return Math.abs(amountPerInterval / DaysInInterval[interval]);
};
