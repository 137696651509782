import React, { FC, createRef, ReactText } from "react";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import AuthContainer from "./containers/auth/AuthContainer";
import AppRoutes from "./AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import AppLayout from "./AppLayout";
import { SnackbarProvider } from "notistack";
import AppStyle from "./App.style";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const App: FC = () => {
  const classes = AppStyle();
  const notistackRef = createRef<any>();

  const onClickDismiss = (key: ReactText) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <div className="App">
      <CssBaseline />
      <Router>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{ containerAnchorOriginTopRight: classes.anchorTop }}
          ref={notistackRef}
          action={(key) => (
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onClickDismiss(key)}
            >
              <CloseIcon />
            </IconButton>
          )}
        >
          <AuthContainer>
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          </AuthContainer>
        </SnackbarProvider>
      </Router>
    </div>
  );
};

export default App;
