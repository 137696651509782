import React, { FC, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { Route } from "../../../routes";
import clsx from "clsx";
import { NavIcons } from "./NavIcons";

interface Props {
  route: Route;
}

const styles = (theme: Theme) =>
  createStyles({
    activeLinkText: {
      color: theme.palette.primary.main,
    },
  });

const makeClasses = makeStyles(styles);

const NavRouteItem: FC<Props> = ({ route, children }) => {
  const classes = makeClasses();
  const location = useLocation();
  const Icon = NavIcons[route];
  const isRouteActive = useMemo(() => location.pathname === route, [
    location,
    route,
  ]);

  return (
    <ListItem button key={route} component={Link} to={route}>
      <ListItemIcon>
        <Icon color={isRouteActive ? "primary" : "action"} />
      </ListItemIcon>
      <ListItemText
        className={clsx({
          [classes.activeLinkText]: isRouteActive,
        })}
      >
        {children}
      </ListItemText>
    </ListItem>
  );
};

export default NavRouteItem;
