import React, { FC } from "react";
import { Formik, Field, Form } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { useSelector, useDispatch } from "react-redux";
import {
  selectShowNewPasswordModal,
  toggleNewPasswordModalAction,
} from "../../../redux/modals.redux";
import {
  selectNewPassworError,
  setNewPasswordFailedAction,
  selectIsAuthInProgress,
  newPasswordCanceledAction,
} from "../auth.redux";
import { Alert } from "@material-ui/lab";
import AuthStyles from "../auth.style";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

interface Values {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

interface Props {
  updatePassword: (oldPassword: string, newPassword: string) => void;
}

const UpdatePassword: FC<Props> = ({ updatePassword }) => {
  const dispatch = useDispatch();
  const showUpdatePassword = useSelector(selectShowNewPasswordModal);
  const newPasswordError = useSelector(selectNewPassworError);
  const isSubmitting = useSelector(selectIsAuthInProgress);
  const { t } = useTranslation();

  const authStyles = AuthStyles();

  const onClose = () => {
    dispatch(toggleNewPasswordModalAction({ show: false }));
    dispatch(newPasswordCanceledAction());
  };

  const initialValues: Values = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
  };

  const onSubmit = ({
    oldPassword,
    newPassword,
    newPasswordRepeat,
  }: Values) => {
    if (newPassword !== newPasswordRepeat) {
      dispatch(
        setNewPasswordFailedAction({
          err: t("auth.newPassword.errors.passwordsNotMatching"),
        })
      );
      return;
    }
    if (newPassword.length < 6) {
      dispatch(
        setNewPasswordFailedAction({
          err: t("auth.newPassword.errors.minLength", { count: 6 }),
        })
      );
      return;
    }
    updatePassword(oldPassword, newPassword);
  };

  return (
    <Dialog
      open={showUpdatePassword}
      onClose={onClose}
      aria-labelledby="new-password-dialog"
      className={authStyles.dialog}
    >
      <DialogTitle id="new-password-dialog" className={authStyles.dialogTitle}>
        {t("auth.newPassword.modal.title")}
      </DialogTitle>
      <DialogContent className={authStyles.dialogContent}>
        <p>{t("auth.newPassword.modal.text")}</p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            newPassword: Yup.string()
              .min(6, t("auth.newPassword.errors.minLength", { count: 6 }))
              .required(t("auth.newPassword.errors.passwordRequired")),
            newPasswordRepeat: Yup.string()
              .min(6, t("auth.newPassword.errors.minLength", { count: 6 }))
              .required(t("auth.newPassword.errors.passwordRepeatRequired")),
          })}
        >
          {({ submitForm }) => (
            <Form className={authStyles.form}>
              <Field
                component={TextField}
                name="oldPassword"
                type="password"
                label={t("auth.newPassword.modal.oldPassword")}
                fullWidth
                className={authStyles.textField}
                disabled={isSubmitting}
              />
              <br />
              <Field
                component={TextField}
                name="newPassword"
                type="password"
                autoComplete="new-password"
                label={t("auth.newPassword.modal.newPassword")}
                fullWidth
                className={authStyles.textField}
                disabled={isSubmitting}
              />
              <br />
              <Field
                component={TextField}
                name="newPasswordRepeat"
                type="password"
                autoComplete="new-password"
                label={t("auth.newPassword.modal.newPasswordRepeat")}
                fullWidth
                className={authStyles.textField}
                disabled={isSubmitting}
              />
              {isSubmitting && <br /> && <LinearProgress />}
              <br />
              {newPasswordError && (
                <Alert severity="error" className={authStyles.alert}>
                  {newPasswordError}
                </Alert>
              )}
              <div className={authStyles.centerDiv}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  className={authStyles.submit}
                >
                  {t("auth.newPassword.modal.submit")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePassword;
