import { combineReducers } from "redux";
import authReducer, { AuthState } from "./containers/auth/auth.redux";
import modalsReducer, { ModalsState } from "./redux/modals.redux";
import configReducer, { ConfigState } from "./redux/config.redux";
import navReducer, { NavState } from "./containers/nav/nav.redux";
import transactionReducer, {
  TransactionState,
} from "./containers/transactions/transaction.redux";
import recurringReducer, {
  RecurringState,
} from "./containers/recurring/recurring.redux";

export interface State {
  auth: AuthState;
  modals: ModalsState;
  config: ConfigState;
  nav: NavState;
  transaction: TransactionState;
  recurring: RecurringState;
}

export const rootReducer = combineReducers({
  auth: authReducer,
  modals: modalsReducer,
  config: configReducer,
  nav: navReducer,
  transaction: transactionReducer,
  recurring: recurringReducer,
});
