export const USFormatNumberRegEx = /^[\d,]*[.]\d{0,2}$/;
export const EUFormatNumberRegEx = /^[\d.]*[,]\d{0,2}$/;

export const i18nStrToNum = (num: string): number => {
  num = num.trim().replace(" ", "");

  // Early return if no special characters are noted
  if (!num.includes(",") && !num.includes(".")) {
    return +num;
  }

  // If US format, remove any commas and convert to number
  // e.g. 1,200,423.21 -> 1200423.21
  if (num.match(USFormatNumberRegEx)) {
    return +num.replace(/[,]/g, "");
  }

  // If EU format, remove any dots and replace the comma with a dot
  // e.g. 1.200.423,21 -> 1200423.21
  if (num.match(EUFormatNumberRegEx)) {
    return +num.replace(/[.]/g, "").replace(/[,]/g, ".");
  }

  return isNaN(+num) ? +num.replace(",", "") : +num;
};
