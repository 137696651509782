import React, { FC } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  selectShowSignupModal,
  toggleSignupModalAction,
} from "../../redux/modals.redux";
import { clearErrorMessagesAction } from "../auth/auth.redux";
import AuthStyles from "../auth/auth.style";
import { useTranslation } from "react-i18next";

const SignUp: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showSignUp = useSelector(selectShowSignupModal);
  const authStyles = AuthStyles();

  const onClose = () => {
    dispatch(toggleSignupModalAction({ show: false }));
    dispatch(clearErrorMessagesAction());
  };
  return (
    <Dialog
      open={showSignUp}
      onClose={onClose}
      aria-labelledby="signup-dialog"
      className={authStyles.dialog}
    >
      <DialogTitle id="signup-dialog" className={authStyles.dialogTitle}>
        {t("auth.signUp.modal.title")}
      </DialogTitle>

      <DialogContent className={authStyles.dialogContent}>
        <h4>{t("auth.signUp.modal.betaHeader")}</h4>
        <p>{t("auth.signUp.modal.betaText")}</p>
        <div className={authStyles.centerDiv}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={onClose}
            className={authStyles.submit}
          >
            {t("auth.signUp.modal.betaClose")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

// TODO: Implement SignUp

// type Values = {
//   email: string;
//   password: string;
//   passwordRepeat: string;
// };

// const initialValues: Values = {
//   email: "",
//   password: "",
//   passwordRepeat: "",
// };

// const Signup: FC = () => {
//   const dispatch = useDispatch();
//   const showSignup = useSelector(selectShowSignupModal);

//   return (
//     <Dialog
//       open={showSignup}
//       onClose={() => dispatch(toggleSignupModalAction({ show: false }))}
//       aria-labelledby="signup-dialog"
//     >
//       <DialogTitle id="signup-dialog">Signup Form</DialogTitle>
//       <Formik
//         initialValues={initialValues}
//         onSubmit={(values) => {
//           console.log("Values: ", values);
//         }}
//       >
//         {({ submitForm, isSubmitting }) => (
//           <Form>
//             <Field
//               component={TextField}
//               name="email"
//               type="email"
//               label="Email"
//               size="small"
//             />
//             <br />
//             <Field
//               component={TextField}
//               type="password"
//               label="Password"
//               size="small"
//               name="password"
//             />
//             <br />
//             <Field
//               component={TextField}
//               type="password"
//               label="Repeat password"
//               size="small"
//               name="passwordRepeat"
//             />
//             <br />
//             <Button
//               variant="contained"
//               color="primary"
//               disabled={isSubmitting}
//               onClick={submitForm}
//               size="small"
//             >
//               Submit
//             </Button>
//           </Form>
//         )}
//       </Formik>
//       <DialogContent></DialogContent>
//     </Dialog>
//   );
// };

export default SignUp;
