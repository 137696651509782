import {
  createAction,
  withInitialState,
  onAction,
  chainReducers,
} from "redux-preboiled";

export interface ConfigState {
  language: string | null;
}

export interface State {
  config: ConfigState;
}

export const setLanguageAction = createAction(
  "config/setLanguage"
).withPayload<{
  language: string;
}>();

export const selectLanguage = (state: State) => state.config.language;

const initialState: ConfigState = {
  language: null,
};

export default chainReducers(
  withInitialState(initialState),
  onAction(setLanguageAction, (state, { payload: { language } }) => ({
    ...state,
    language,
  }))
);
