import { FC } from "react";
import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const RecurringListSkeleton: FC = () => {
  return (
    <>
      {["RecSkel1", "RecSkel2", "RecSkel3"].map((item) => (
        <TableRow key={item}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="circle" width={20} height={20} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default RecurringListSkeleton;
