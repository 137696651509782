import { createStyles, Theme, makeStyles } from "@material-ui/core";

const recurringStyle = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1, 3, 3),
    },
    form: {},
    input: {
      width: "100%",
    },
    divider: {
      marginBottom: theme.spacing(2),
    },
  });

export default makeStyles(recurringStyle);
