import React, { FC } from "react";
import Login from "./components/Login";
import UpdatePassword from "./components/UpdatePassword";
import { useLogin, useUpdatePassword } from "../../hooks/auth";
import { AuthUser } from "../../models/Auth.model";

const AuthContainer: FC = ({ children }) => {
  const loginUser = useLogin();
  const updatePassword = useUpdatePassword();

  const onLogin = (authUser: AuthUser) => loginUser(authUser);

  return (
    <>
      <Login loginUser={onLogin} />
      <UpdatePassword updatePassword={updatePassword} />
      {children}
    </>
  );
};

export default AuthContainer;
