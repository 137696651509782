import { useDispatch, useSelector, useStore } from "react-redux";
// import { useSnackbar } from "notistack";
// import { useTranslation } from "react-i18next";
import {
  CognitoUserSession,
  CognitoUser,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import {
  selectCognitoUsername,
  selectIsAccessTokenValid,
  selectAccessTokenJwt,
  selectIsRefreshingToken,
  selectRefreshToken,
  selectIsLoggedIn,
  startedTokenRefresh,
  successfulTokenRefresh,
  failedTokenRefresh,
} from "../containers/auth/auth.redux";
import { userPool, apiUrl } from "../Environment";
import Axios, { AxiosRequestConfig } from "axios";
import { useCallback } from "react";
import { CognitoError, AWS_COGNITO_ERROR } from "../Errors";

export const useApi = () => {
  const dispatch = useDispatch();
  const cognitoUsername = useSelector(selectCognitoUsername);
  const hasValidAccessToken = useSelector(selectIsAccessTokenValid);
  const accessTokenJwt = useSelector(selectAccessTokenJwt);
  const isRefreshingToken = useSelector(selectIsRefreshingToken);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const refreshToken = useSelector(selectRefreshToken);
  const store = useStore();

  const refreshSession = useCallback(
    (refreshToken: string): Promise<CognitoUserSession> => {
      const user = new CognitoUser({
        Username: cognitoUsername as string,
        Pool: userPool,
      });
      if (!user) return Promise.reject();

      dispatch(startedTokenRefresh());

      return new Promise((resolve, reject) => {
        user.refreshSession(
          new CognitoRefreshToken({ RefreshToken: refreshToken }),
          (err, session: CognitoUserSession) => {
            if (err) {
              dispatch(failedTokenRefresh());
              //TODO
              return reject();
            }
            dispatch(successfulTokenRefresh({ session }));
            return resolve(session);
          }
        );
      });
    },
    [cognitoUsername, dispatch]
  );

  return {
    request: useCallback(
      <T>(config: AxiosRequestConfig): Promise<T> => {
        config = { ...config, baseURL: apiUrl };
        if (hasValidAccessToken)
          return Axios.request<T>({
            ...config,
            headers: { ...config.headers, Authorization: accessTokenJwt },
          }).then((result) => result.data);

        if (!refreshToken)
          return Promise.reject(
            new CognitoError(AWS_COGNITO_ERROR.LOGIN_EXPIRED, "Login Expired")
          );

        if (isRefreshingToken)
          return new Promise((res, rej) => {
            const unsub = store.subscribe(() => {
              if (!isRefreshingToken) {
                unsub();
                if (!isLoggedIn)
                  return rej(
                    new CognitoError(
                      AWS_COGNITO_ERROR.LOGIN_EXPIRED,
                      "Login Expired"
                    )
                  );
                return Axios.request<T>({
                  ...config,
                  headers: { ...config.headers, Authorization: accessTokenJwt },
                }).then((result) => result.data);
              }
            });
          });
        return refreshSession(refreshToken).then((token) => {
          return Axios.request<T>({
            ...config,
            headers: {
              ...config.headers,
              Authorization: token.getAccessToken().getJwtToken(),
            },
          }).then((result) => result.data);
        });
      },
      [
        accessTokenJwt,
        hasValidAccessToken,
        isLoggedIn,
        isRefreshingToken,
        refreshSession,
        refreshToken,
        store,
      ]
    ),

    requestUnprotected: useCallback(
      (config: AxiosRequestConfig) =>
        Axios.request({ ...config, baseURL: apiUrl }),
      []
    ),
  };
};
