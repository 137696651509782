import {
  createAction,
  withInitialState,
  onAction,
  chainReducers,
} from "redux-preboiled";

export interface NavState {
  showNavigation: boolean;
}

export interface State {
  nav: NavState;
}

const initialState: NavState = {
  showNavigation: false,
};

export const selectShowNavigation = (state: State) => state.nav.showNavigation;

export const toggleNavAction = createAction("nav/toggleNav").withPayload<{
  show: boolean;
}>();

export default chainReducers(
  withInitialState(initialState),
  onAction(toggleNavAction, (state, { payload: { show } }) => ({
    ...state,
    showNavigation: show,
  }))
);
