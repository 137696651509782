import { makeStyles, Theme, createStyles } from "@material-ui/core";

const authStyles = (theme: Theme) =>
  createStyles({
    dialog: {
      minWidth: "50em",
    },
    dialogTitle: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      minWidth: "25em",
    },
    dialogContent: {
      margin: theme.spacing(1, 0),
      color: theme.palette.common.black,
    },
    form: {},
    textField: {
      marginBottom: theme.spacing(2),
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
    submit: {
      marginBottom: theme.spacing(1),
    },
    centerDiv: {
      textAlign: "center",
    },
    noAccountText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  });

export default makeStyles(authStyles);
