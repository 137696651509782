import * as Yup from "yup";
import { EUFormatNumberRegEx } from "./numbers";

//
// Added String Validation Methods
//

Yup.addMethod(Yup.string, "isLocaleNumber", (message?) => {
  return Yup.string().test(
    "isLocaleNumber",
    message || "No recognized number format",
    (value) => {
      return (
        // Undefineds are caught by required()
        typeof value === "undefined" ||
        !isNaN(+value) ||
        value.match(EUFormatNumberRegEx)
      );
    }
  );
});

/**
 * Yup.StringSchema with added methods
 */
export interface EnhancedStringSchema extends Yup.StringSchema {
  isLocaleNumber: (message?: string) => EnhancedStringSchema;
}

/**
 * Wraps the original Yup.string() and casts the return type to EnhancedStringSchema
 */
const string = () => {
  return Yup.string() as EnhancedStringSchema;
};

export default { ...Yup, string };
