import { useMemo } from "react";
import { FieldProps, Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";

interface Props<T> extends FieldProps {
  categories: { value: T; text: string }[];
}

const CategorySelect = <T,>({ categories }: Props<T>) => {
  const { t } = useTranslation();

  // Orderes categories alpabetically after translation,
  // sets "TBD" is initial select and "other" as last.
  const sortedCategories = useMemo(() => {
    let translatedCategories = categories.map((item) => ({
      value: item.value,
      text: t(item.text),
    }));
    // firstItem = _TBD
    let firstItem = translatedCategories.slice(0, 1);

    let orderedRemainingItems = translatedCategories
      .slice(1, -1)
      .sort((a, b) => (a.text > b.text ? 1 : -1));

    // lastItem = other
    let lastItem = translatedCategories.slice(-1);
    return [...firstItem, ...orderedRemainingItems, ...lastItem].map(
      (category) => (
        <MenuItem value={category.value as any} key={category.value as any}>
          {category.text}
        </MenuItem>
      )
    );
  }, [categories, t]);

  return (
    <Field
      component={Select}
      name="category"
      inputProps={{ id: "category-recurring" }}
      fullWidth
    >
      {sortedCategories}
    </Field>
  );
};

export default CategorySelect;
