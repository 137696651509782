import { createStyles, Theme, makeStyles } from "@material-ui/core";

const transactionStyles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1, 3, 3),
    },
    form: {},
    divider: {
      marginBottom: theme.spacing(2),
    },
    actionItems: {
      textAlign: "right",
    },
    actionItem: {
      marginRight: theme.spacing(2),
    },
  });

export default makeStyles(transactionStyles);
