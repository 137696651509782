import {
  createAction,
  chainReducers,
  withInitialState,
  onAction,
} from "redux-preboiled";
import {
  loginSucceededAction,
  newPasswordRequiredAction,
  setNewPasswordSucceededAction,
} from "../containers/auth/auth.redux";

export interface ModalsState {
  showLoginModal: boolean;
  showSignupModal: boolean;
  showNewPasswordModal: boolean;
}

interface State {
  modals: ModalsState;
}

export const toggleLoginModalAction = createAction(
  "nav/toggleLoginModal"
).withPayload<{
  show: boolean;
}>();
export const toggleSignupModalAction = createAction(
  "nav/toggleSignupModal"
).withPayload<{
  show: boolean;
}>();
export const toggleNewPasswordModalAction = createAction(
  "nav/toggleNewPasswordModal"
).withPayload<{ show: boolean }>();

export const selectShowLoginModal = (state: State) => {
  return state.modals.showLoginModal;
};

export const selectShowSignupModal = (state: State) => {
  return state.modals.showSignupModal;
};

export const selectShowNewPasswordModal = (state: State) => {
  return state.modals.showNewPasswordModal;
};

const initialState: ModalsState = {
  showLoginModal: false,
  showSignupModal: false,
  showNewPasswordModal: false,
};

export default chainReducers(
  withInitialState(initialState),
  onAction(toggleLoginModalAction, (_state, { payload: { show } }) => {
    return {
      ...initialState,
      showLoginModal: show,
    };
  }),
  onAction(toggleSignupModalAction, (_state, { payload: { show } }) => {
    return {
      ...initialState,
      showSignupModal: show,
    };
  }),
  onAction(toggleNewPasswordModalAction, (_state, { payload: { show } }) => {
    return {
      ...initialState,
      showNewPasswordModal: show,
    };
  }),
  onAction(loginSucceededAction, () => {
    return {
      ...initialState,
      showLoginModal: false,
    };
  }),
  onAction(newPasswordRequiredAction, () => {
    return {
      ...initialState,
      showNewPasswordModal: true,
    };
  }),
  onAction(setNewPasswordSucceededAction, () => {
    return {
      ...initialState,
      showNewPasswordModal: false,
    };
  })
);
