import { makeStyles, createStyles } from "@material-ui/core";

const AppStyles = () =>
  createStyles({
    anchorTop: {
      top: 72,
    },
  });

export default makeStyles(AppStyles);
