import React, { FC } from "react";
import { Button } from "@material-ui/core";
import { useLogout } from "../../../hooks/auth";
import { useTranslation } from "react-i18next";

const LogoutButton: FC = () => {
  const { t } = useTranslation();
  const logout = useLogout();
  return (
    <Button color="inherit" onClick={() => logout()}>
      {t("auth.logout.button")}
    </Button>
  );
};

export default LogoutButton;
