import React, { FC } from "react";
import {
  TableCell,
  makeStyles,
  createStyles,
  TableRow,
} from "@material-ui/core";

const styles = createStyles({
  emptyText: {
    textAlign: "center",
    fontStyle: "italic",
  },
});

const makeClasses = makeStyles(styles);

const TransactionEmptyListItem: FC = () => {
  const classes = makeClasses();

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <p className={classes.emptyText}>
          You have not yet created any transactions
        </p>
      </TableCell>
    </TableRow>
  );
};

export default TransactionEmptyListItem;
