import React, { FC } from "react";
import CustomAppBar from "./containers/customAppBar/CustomAppBar";
import SignUpContainer from "./containers/signUp/SignUpContainer";
import NavContainer from "./containers/nav/NavContainer";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/de";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSelector } from "react-redux";
import { selectLanguage } from "./redux/config.redux";

const AppLayout: FC = ({ children }) => {
  const lang = useSelector(selectLanguage);

  moment.locale(lang || "en");

  return (
    <>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={lang}
      >
        <CustomAppBar />
        <NavContainer />
        <SignUpContainer />
        <div
          style={{
            marginTop: 92,
            marginLeft: 100,
            marginRight: 24,
            flexGrow: 1,
          }}
        >
          {children}
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};
export default AppLayout;
