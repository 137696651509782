import { Route } from "../../../routes";

import RootIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import StatisticsIcon from "@material-ui/icons/InsertChart";
import RecurringIcon from "@material-ui/icons/MonetizationOn";
import TransactionIcon from "@material-ui/icons/SwapHorizontalCircle";
import SignupIcon from "@material-ui/icons/PersonAdd";
import LoginIcon from "@material-ui/icons/ExitToApp";
import LogoutIcon from "@material-ui/icons/Cancel";
import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import ImprintIcon from "@material-ui/icons/Subject";
import { NavAction } from "../../../models/Nav.model";

export const NavIcons: Record<
  Route | NavAction,
  OverridableComponent<SvgIconTypeMap>
> = {
  [Route.ROOT]: RootIcon,
  [Route.TRANSACTIONS]: TransactionIcon,
  [Route.STATISTICS]: StatisticsIcon,
  [Route.RECURRING]: RecurringIcon,
  [Route.SETTINGS]: SettingsIcon,
  [Route.IMPRINT]: ImprintIcon,
  [NavAction.LOGIN]: LoginIcon,
  [NavAction.LOGOUT]: LogoutIcon,
  [NavAction.SIGNUP]: SignupIcon,
};
