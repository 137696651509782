import { FC } from "react";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    amount: {
      textAlign: "center",
    },
    expense: {
      color: theme.palette.error.dark,
    },
    earning: {
      color: theme.palette.success.dark,
    },
  });

const makeClasses = makeStyles(styles);

type Props = {
  amount: number;
};

const StyledAmount: FC<Props> = ({ children, amount }) => {
  const classes = makeClasses();

  return (
    <span
      className={clsx(classes.amount, {
        [classes.expense]: amount < 0,
        [classes.earning]: amount > 0,
      })}
    >
      {children}
    </span>
  );
};

export default StyledAmount;
