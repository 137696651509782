import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { CognitoUserSession } from "amazon-cognito-identity-js";

export enum LoginResponseType {
  LOGIN_SUCCESS,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_REQUIRED,
}

type LoginResponse = {
  type: LoginResponseType;
  session?: CognitoUserSession;
};

export class AuthService {
  static loginWithCredentials(
    user: CognitoUser,
    credentials: AuthenticationDetails,
    newPassword?: string
  ): Promise<LoginResponse> {
    return new Promise((res, rej) => {
      user.authenticateUser(credentials, {
        newPasswordRequired: () => {
          console.log("New password required!");
          if (!newPassword) {
            return res({ type: LoginResponseType.NEW_PASSWORD_REQUIRED });
          }
          user.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: (session) => {
                return res({
                  type: LoginResponseType.NEW_PASSWORD_SUCCESS,
                  session,
                });
              },
              onFailure: (err) => {
                return rej(err);
              },
            }
          );
        },
        onSuccess: (result) => {
          console.log("Resut: ", result);
          return res({
            type: LoginResponseType.LOGIN_SUCCESS,
            session: result,
          });
        },
        onFailure: (err) => {
          console.log("Error: ", err);
          return rej(err);
        },
      });
    });
  }
}
