import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { TextFieldProps, TextField } from "formik-material-ui";
import { InputAdornment } from "@material-ui/core";
import amountInputStyle from "./amountInput.style";

interface Props extends Partial<TextFieldProps> {
  classes: Record<string, string>;
  isIncome: boolean;
  labelId: string;
}

const AmountInput: FC<Props> = ({
  classes: inputClasses,
  isIncome,
  labelId,
  field,
  disabled,
  form,
}) => {
  const { t } = useTranslation();
  const ownClasses = amountInputStyle();
  const classes: Record<string, string> = useMemo(
    () => ({ ...ownClasses, ...inputClasses }),
    [ownClasses, inputClasses]
  );
  return (
    <Field
      fullWidth
      component={TextField}
      name={field?.name}
      onBlur={field?.onBlur}
      onChange={field?.onChange}
      disabled={disabled || form?.isSubmitting}
      InputProps={{
        startAdornment: isIncome ? (
          <InputAdornment position="start">+</InputAdornment>
        ) : (
          <InputAdornment position="start">-</InputAdornment>
        ),
        endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
      }}
      label={t(labelId || "form.component.input.amount.labels.amount")}
      className={classes.input}
    />
  );
};

export default AmountInput;
