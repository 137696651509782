import React, { FC } from "react";
import { ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { NavIcons } from "./NavIcons";
import { NavAction } from "../../../models/Nav.model";

interface Props {
  onClick: () => any;
  action: NavAction;
}

const NavActionItem: FC<Props> = ({ onClick, action, children }) => {
  const Icon = NavIcons[action];
  return (
    <ListItem button key={action} onClick={onClick}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </ListItem>
  );
};

export default NavActionItem;
