import React, { FC, useEffect, useMemo } from "react";
import CreateTransactionForm from "../../forms/transactions/CreateTransactionForm";
import Grid from "@material-ui/core/Grid";
import { Paper, Divider } from "@material-ui/core";
import transactionStyle from "./transaction.style";
import TransactionList from "./lists/TransactionList";
import { useTransactionService } from "./transaction.service";
import { useDispatch, useSelector } from "react-redux";
import {
  gettingTransactionsAction,
  selectTransactions,
  gettingTransactionsSuccessfulAction,
  gettingTransactionsFailedAction,
  selectIsGettingTransactions,
} from "./transaction.redux";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Atoms } from "../../atoms/Atoms";

const TransactionsContainer: FC = () => {
  const classes = transactionStyle();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const transactions = useSelector(selectTransactions);
  const isGettingTransactions = useSelector(selectIsGettingTransactions);
  const { getTransactions } = useTransactionService();

  useEffect(() => {
    dispatch(gettingTransactionsAction());
    getTransactions({ limit: 5 })
      .then((transactions) => {
        dispatch(gettingTransactionsSuccessfulAction({ transactions }));
      })
      .catch((err) => {
        snackbar.enqueueSnackbar(t("notification.transaction.fetchError"), {
          variant: "error",
        });
        dispatch(gettingTransactionsFailedAction(err.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sorted = useMemo(() => {
    return transactions.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }, [transactions]);

  return (
    <Grid container component="div" spacing={3}>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Paper className={classes.paper}>
          <Atoms.PaperHeader>
            {t("transaction.createForm.title")}
          </Atoms.PaperHeader>
          <Divider className={classes.divider} />
          <CreateTransactionForm classes={classes} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={8} xl={9}>
        <Paper className={classes.paper}>
          <Atoms.PaperHeader>
            {t("transaction.list.title")}
            {isGettingTransactions && <Atoms.CircularProgress />}
          </Atoms.PaperHeader>
          <Divider className={classes.divider} />
          <TransactionList classes={classes} transactions={sorted} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TransactionsContainer;
