import { FC } from "react";
import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const TransactionListSkeleton: FC = () => {
  return (
    <>
      {["TraSkel1", "TraSkel2", "TraSkel3"].map((item) => (
        <TableRow key={item}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="circle" width={20} height={20} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TransactionListSkeleton;
