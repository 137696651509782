import React, { FC, useMemo, useCallback, useState } from "react";
import { Transaction } from "../../../models/Transaction.model";
import {
  TableCell,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { selectLanguage } from "../../../redux/config.redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import { useTransactionService } from "../transaction.service";
import { useSnackbar } from "notistack";
import { deletingTransactionSuccessfulAction } from "../transaction.redux";
import { Atoms } from "../../../atoms/Atoms";

interface Props {
  classes: Record<any, string>;
  transaction: Transaction;
}

const TransactionListItem: FC<Props> = ({ transaction, classes }) => {
  const lang = useSelector(selectLanguage) as string;
  const { deleteTransaction } = useTransactionService();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const local = useSelector(selectLanguage) as string;

  const [isDeleting, setIsDeleting] = useState(false);

  const date = useMemo(
    () => new Date(transaction.date).toLocaleDateString(lang),
    [lang, transaction.date]
  );
  const { t } = useTranslation();

  const deleteItem = useCallback(
    (transaction: Transaction) => {
      setIsDeleting(true);
      deleteTransaction(transaction)
        .then(() => {
          snackbar.enqueueSnackbar(
            t("notification.transaction.deleteSuccess", {
              amount: transaction.amount,
            }),
            { variant: "success" }
          );
          dispatch(deletingTransactionSuccessfulAction({ transaction }));
        })
        .catch(() => {
          snackbar.enqueueSnackbar(t("notification.transaction.deleteError"), {
            variant: "error",
          });
        })
        .finally(() => {
          setIsDeleting(false);
        });
    },
    [deleteTransaction, snackbar, t, dispatch]
  );

  const amount = useMemo(
    () =>
      new Intl.NumberFormat(local, {
        style: "currency",
        currency: transaction.currency.toString(),
      }).format(transaction.amount),
    [transaction, local]
  );

  return (
    <>
      <TableCell>{date}</TableCell>
      <TableCell>
        <Atoms.StyledAmount amount={transaction.amount}>
          {amount}
        </Atoms.StyledAmount>
      </TableCell>
      <TableCell>
        {t(`transaction.categories.${transaction.category?.toLowerCase()}`) ||
          ""}
      </TableCell>
      <TableCell>{transaction.description?.toString() || ""}</TableCell>
      <TableCell className={classes.actionItems}>
        {!isDeleting && (
          <Tooltip title={t("transaction.list.action.delete").toString()}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                deleteItem(transaction);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {isDeleting && <CircularProgress size={28} />}
      </TableCell>
    </>
  );
};

export default TransactionListItem;
