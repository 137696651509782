import React, { FC, useCallback } from "react";
import { Formik, FormikHelpers, Field, Form } from "formik";
import { TransactionCreationForm } from "../../models/Transaction.model";
import { Currency } from "../../models/Currency.enum";
import { useTransactionService } from "../../containers/transactions/transaction.service";
import {
  Grid,
  InputAdornment,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSnackbar } from "notistack";
import DescriptionIcon from "@material-ui/icons/Description";
import { useDispatch } from "react-redux";
import { createdTransactionAction } from "../../containers/transactions/transaction.redux";
import AmountInput from "../components/inputs/amountInput/AmountInput";
import IncomeSwitchInput from "../components/inputs/incomeSwitchInput/IncomeSwitchInput";
import DatePicker from "../components/inputs/datePicker/DatePicker";
import Yup from "../../utils/validation";
import CategorySelect from "../components/inputs/categorySelect/CategorySelect";
import {
  TransactionCategories,
  TransactionCategory,
} from "../../models/Category.model";

type Props = {
  classes: Record<any, string>;
};

const initialValues: TransactionCreationForm = {
  isIncome: false,
  amount: "",
  currency: Currency.EUR,
  category: TransactionCategory._TDB,
  date: moment(),
  writeOff: 0,
  description: "",
};

const CreateTransactionForm: FC<Props> = ({ classes }) => {
  const { t } = useTranslation();
  const { createTransaction } = useTransactionService();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  initialValues.amount = t("transaction.createForm.values.initialAmount");

  const onSubmit = useCallback(
    (
      values: TransactionCreationForm,
      { setSubmitting, setValues }: FormikHelpers<TransactionCreationForm>
    ) => {
      setSubmitting(true);
      createTransaction(values)
        .then((result) => {
          dispatch(createdTransactionAction({ transaction: result }));
          snackbar.enqueueSnackbar(
            t("notification.transaction.createdSuccess"),
            {
              variant: "success",
            }
          );
          setValues({ ...initialValues, date: values.date }, false);
        })
        .catch((_err) => {
          snackbar.enqueueSnackbar(t("notification.generalError.unexpected"), {
            variant: "error",
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [createTransaction, dispatch, snackbar, t]
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          amount: Yup.string()
            .isLocaleNumber(t("form.error.invalidNumberFormat"))
            .required(t("form.error.amountRequired")),
          isIncome: Yup.bool().required(),
          date: Yup.date().nullable().required(t("form.error.dateRequired")),
          description: Yup.string().max(
            128,
            t("form.error.maxLength", { max: 128 })
          ),
        })}
      >
        {({ values, isSubmitting, submitForm }) => {
          return (
            <Form>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <Field
                    component={IncomeSwitchInput}
                    name="isIncome"
                    classes={classes}
                  ></Field>
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <Field
                      component={AmountInput}
                      name="amount"
                      isIncome={values.isIncome}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      fullWidth
                      component={DatePicker}
                      label={t("transaction.createForm.labels.date")}
                      name="date"
                      disabled={isSubmitting}
                      cancelLabel={t("form.shared.cancel")}
                      okLabel={t("form.shared.ok")}
                      clearable={false}
                      format={t("format.localeDate")}
                      className={classes.input}
                      disablePast={false}
                      //minDate={moment().startOf("year")}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <Field
                      component={CategorySelect}
                      name="category"
                      categories={TransactionCategories}
                    ></Field>
                  </Grid>
                </Grid>
                <Grid item>
                  <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    name="description"
                    label={t("transaction.createForm.labels.description")}
                    disabled={isSubmitting}
                    className={classes.input}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <DescriptionIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  {isSubmitting && <LinearProgress></LinearProgress>}
                </Grid>
                <Grid item container justify="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {t("transaction.createForm.labels.submit")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateTransactionForm;
