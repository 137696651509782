import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import de from "./locales/de.json";
import en from "./locales/en.json";
import { browserLang } from "../Environment";
import moment from "moment";

const resources = {
  de: { translation: de },
  en: { translation: en },
};

interface FallbackLngObjList {
  [language: string]: string[];
}

const fallbackLng: FallbackLngObjList = {
  "de-AT": ["de", "en"],
  "de-CH": ["de", "en"],
  "de-DE": ["de", "en"],
  "de-LU": ["de", "en"],
  default: ["en"],
};

i18n.use(initReactI18next).init({
  lng: browserLang,
  fallbackLng,
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (value instanceof moment) {
        return moment(value).format(format);
      }
      return value;
    },
  },
  resources,
  whitelist: Object.keys(resources),
});

export default i18n;
