import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Route } from "../../routes";
const NoAuth: FC = () => {
  return (
    <>
      Please log in to see this page or return <Link to={Route.ROOT}>home</Link>
    </>
  );
};

export default NoAuth;
