import { FC } from "react";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";

const styles = () =>
  createStyles({
    h2: {
      textAlign: "center",
    },
  });

const makeClasses = makeStyles(styles);

const PaperHeader: FC = ({ children }) => {
  const classes = makeClasses();

  return <h2 className={classes.h2}>{children}</h2>;
};

export default PaperHeader;
