import React, { FC } from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoggedIn } from "../auth/auth.redux";
import LoginButton from "./components/LoginButton";
import LogoutButton from "./components/LogoutButton";
import LanguageSelect from "./components/LanguageSelect";
import appBarStyle from "./customAppBar.style";
import { selectShowNavigation, toggleNavAction } from "../nav/nav.redux";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { getRouteTitle } from "../../routes";

const CustomAppBar: FC = () => {
  const classes = appBarStyle();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const showNav = useSelector(selectShowNavigation);

  const openNav = () => dispatch(toggleNavAction({ show: true }));
  const location = useLocation();

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: showNav,
        })}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={openNav}
            className={clsx(classes.menuButton, {
              [classes.hide]: showNav,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title} noWrap>
            {getRouteTitle(location.pathname, isLoggedIn)}
          </Typography>
          <div>
            {isLoggedIn ? <LogoutButton /> : <LoginButton />} |&nbsp;&nbsp;
            <LanguageSelect />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default CustomAppBar;
