import { State } from "./root.redux";
import { getPreferredLanguage } from "./utils/browser";

const STATE_KEY = "state";

const defaultInitialState: Partial<State> = {
  config: {
    language: getPreferredLanguage(),
  },
};

export const persistState = (state: State) => {
  try {
    localStorage.setItem(STATE_KEY, JSON.stringify(state));
  } catch (err) {
    console.error("Failed to persist state: ", err);
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STATE_KEY);
    if (!serializedState) return defaultInitialState;
    return { ...defaultInitialState, ...JSON.parse(serializedState) };
  } catch (err) {
    console.error("Failed to load state: ", err);
    return defaultInitialState;
  }
};

export const loadedState = loadState();
