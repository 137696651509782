import React, { FC } from "react";
import { Drawer, IconButton, Divider, List } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NavStyles from "../nav.style";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Route } from "../../../routes";
import NavRouteItem from "./NavRouteItem";
import NavActionItem from "./NavActionItem";
import { NavAction } from "../../../models/Nav.model";

export type Props = {
  handleClose: () => any;
  handleOpen: () => any;
  showNav: boolean;
  isLoggedIn: boolean;
  openSignUp: () => any;
  openLogin: () => any;
  handleLogout: () => any;
};

const NavLayout: FC<Props> = ({
  handleClose,
  handleOpen,
  showNav,
  isLoggedIn,
  openLogin,
  openSignUp,
  handleLogout,
}) => {
  const { t } = useTranslation();
  const navClasses = NavStyles();

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(navClasses.drawer, {
          [navClasses.drawerOpen]: showNav,
          [navClasses.drawerClose]: !showNav,
        })}
        classes={{
          paper: clsx({
            [navClasses.drawerOpen]: showNav,
            [navClasses.drawerClose]: !showNav,
          }),
        }}
      >
        <div className={navClasses.toolbar}>
          {!showNav && (
            <IconButton onClick={handleOpen}>
              <ChevronRightIcon />
            </IconButton>
          )}
          {showNav && (
            <IconButton onClick={handleClose}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </div>
        <Divider />
        <List>
          <NavRouteItem route={Route.ROOT}>
            {t("nav.items.overview")}
          </NavRouteItem>
          {isLoggedIn && (
            <>
              <NavRouteItem route={Route.TRANSACTIONS}>
                {t("nav.items.transactions")}
              </NavRouteItem>

              <NavRouteItem route={Route.STATISTICS}>
                {t("nav.items.statistics")}
              </NavRouteItem>

              <NavRouteItem route={Route.RECURRING}>
                {t("nav.items.recurringCosts")}
              </NavRouteItem>
            </>
          )}
        </List>
        <Divider />
        <List>
          {isLoggedIn && (
            <>
              <NavRouteItem route={Route.SETTINGS}>
                {t("nav.items.recurringCosts")}
              </NavRouteItem>
              <NavActionItem onClick={handleLogout} action={NavAction.LOGOUT}>
                {t("nav.items.logout")}
              </NavActionItem>
            </>
          )}
          {!isLoggedIn && (
            <>
              <NavActionItem onClick={openLogin} action={NavAction.LOGIN}>
                {t("nav.items.login")}
              </NavActionItem>
              <NavActionItem onClick={openSignUp} action={NavAction.SIGNUP}>
                {t("nav.items.signUp")}
              </NavActionItem>
            </>
          )}
        </List>
        <div className={navClasses.flexGrow}></div>
        <List>
          {/* impressum = German for "imprint", legal requirement in Germany */}
          <NavRouteItem route={Route.IMPRINT}>
            {t("nav.items.imprint")}
          </NavRouteItem>
        </List>
      </Drawer>
    </>
  );
};

export default NavLayout;
