import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./root.redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadedState } from "./LocalStorage";

const configureStore = () => {
  const composeEnhancers = composeWithDevTools({
    trace: true,
  });
  const store = createStore(
    rootReducer,
    { ...loadedState, auth: { ...loadedState.auth, isRefreshingToken: false } },
    // if epics
    //composeEnhancers(applyMiddleware(epicMiddleware))
    composeEnhancers(applyMiddleware())
  );
  return store;
};

export default configureStore;
