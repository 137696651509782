import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const GuestHomepage: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1>{t("homepage.guest.title")}</h1>
      <p>{t("homepage.guest.text")}</p>
    </>
  );
};

export default GuestHomepage;
