import React, { FC } from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { toggleLoginModalAction } from "../../../redux/modals.redux";
import { useTranslation } from "react-i18next";

const LoginButton: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Button
      color="inherit"
      onClick={() => {
        dispatch(toggleLoginModalAction({ show: true }));
      }}
    >
      {t("auth.login.button")}
    </Button>
  );
};

export default LoginButton;
