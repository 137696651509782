export enum Interval {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUATERLY = "QUATERLY",
  HALF_YEARLY = "HALF_YEARLY",
  YEARLY = "YEARLY",
}

export const DaysInInterval: Record<Interval, number> = {
  DAILY: 1,
  WEEKLY: 7,
  MONTHLY: 31,
  QUATERLY: 91,
  HALF_YEARLY: 182,
  YEARLY: 365,
};

export type IntervalType = {
  value: Interval;
  text: string;
};

export const Intervals: IntervalType[] = [
  {
    value: Interval.DAILY,
    text: "recurring.intervals.daily",
  },
  {
    value: Interval.WEEKLY,
    text: "recurring.intervals.weekly",
  },
  {
    value: Interval.MONTHLY,
    text: "recurring.intervals.monthly",
  },
  {
    value: Interval.QUATERLY,
    text: "recurring.intervals.quaterly",
  },
  {
    value: Interval.HALF_YEARLY,
    text: "recurring.intervals.halfYearly",
  },
  {
    value: Interval.YEARLY,
    text: "recurring.intervals.yearly",
  },
];
