import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import TransactionListItem from "./TransactionListItem";
import { Transaction } from "../../../models/Transaction.model";
import TransactionListSkeleton from "./TransactionListSkeleton";
import { useSelector } from "react-redux";
import { selectIsGettingTransactions } from "../transaction.redux";
import TransactionListEmptyItem from "./TransactionListEmptyItem";

interface Props {
  classes: Record<any, string>;
  transactions: Transaction[];
}

const TransactionList: FC<Props> = ({ classes, transactions }) => {
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsGettingTransactions);

  return (
    <>
      <TableContainer>
        <Table aria-label="List of transactions">
          <TableHead>
            <TableRow key={"header"}>
              <TableCell>{t("transaction.list.header.date")}</TableCell>
              <TableCell>{t("transaction.list.header.amount")}</TableCell>
              <TableCell>{t("transaction.list.header.category")}</TableCell>
              <TableCell>{t("transaction.list.header.description")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              transactions.map((transaction) => (
                <TableRow
                  key={transaction.createdTime}
                  hover
                  classes={{ hover: classes.hover }}
                >
                  <TransactionListItem
                    transaction={transaction}
                    classes={classes}
                  ></TransactionListItem>
                </TableRow>
              ))}
            {!isLoading && transactions.length === 0 && (
              <TransactionListEmptyItem />
            )}
            {isLoading && <TransactionListSkeleton />}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TransactionList;
