import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Link as MuiLink } from "@material-ui/core";
import { setLanguageAction } from "../../../redux/config.redux";
import { persistState } from "../../../LocalStorage";
import { store } from "../../..";
import i18n from "../../../i18n/i18n";
const LanguageSelect: FC = () => {
  const dispatch = useDispatch();

  const toggleLanguage = (language: string) => {
    dispatch(setLanguageAction({ language: language }));
    persistState(store.getState()); // Force save to local storage even if throttled
    i18n.changeLanguage(language);
  };

  return (
    <>
      <MuiLink
        component="button"
        variant="body2"
        color="inherit"
        onClick={() => toggleLanguage("de-DE")}
      >
        DE
      </MuiLink>{" "}
      /
      <MuiLink
        component="button"
        variant="body2"
        color="inherit"
        onClick={() => toggleLanguage("en-US")}
      >
        EN
      </MuiLink>
    </>
  );
};
export default LanguageSelect;
