import i18n from "./i18n/i18n";

export enum Route {
  ROOT = "/",
  TRANSACTIONS = "/transactions",
  STATISTICS = "/statistics",
  RECURRING = "/recurring",
  SETTINGS = "/settings",
  // "Impressum" = German for "imprint" - Legal requirement in Germany
  IMPRINT = "/impressum",
}

export const getRouteTitle = (
  pathname: string,
  isLoggedIn: boolean
): string => {
  switch (pathname) {
    case Route.ROOT:
      return i18n.t("nav.titles.overview");
    case Route.TRANSACTIONS:
      return isLoggedIn
        ? i18n.t("nav.titles.transactions")
        : i18n.t("nav.titles.loginRequired");
    case Route.STATISTICS:
      return isLoggedIn
        ? i18n.t("nav.titles.statistics")
        : i18n.t("nav.titles.loginRequired");
    case Route.RECURRING:
      return isLoggedIn
        ? i18n.t("nav.titles.recurringCosts")
        : i18n.t("nav.titles.loginRequired");
    case Route.SETTINGS:
      return isLoggedIn
        ? i18n.t("nav.titles.settings")
        : i18n.t("nav.titles.loginRequired");
    case Route.IMPRINT:
      return i18n.t("nav.titles.imprint");
    default:
      return i18n.t("nav.titles.error");
  }
};

export const shouldKeepRouteAtLogin = (pathname: string) =>
  Object.keys(Route).includes(pathname);
