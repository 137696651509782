export enum AWS_COGNITO_ERROR {
  NOT_AUTHORIZED_EXCEPTION = "NotAuthorizedException",
  LOGIN_EXPIRED = "LoginExpired",
}

export class CognitoError implements Error {
  name: string | AWS_COGNITO_ERROR;
  message: string;

  constructor(name: string | AWS_COGNITO_ERROR, message: string) {
    this.name = name;
    this.message = message;
  }
}
