import { Theme, createStyles, makeStyles } from "@material-ui/core";

const incomeSwitchInputStyles = (theme: Theme) =>
  createStyles({
    highlightEarning: {
      fontWeight: "bold",
      color: theme.palette.success.main,
    },
    highlightExpense: {
      fontWeight: "bold",
      color: theme.palette.error.main,
    },
    expense: {
      paddingRight: theme.spacing(1),
    },
    earning: {
      paddingLeft: theme.spacing(1),
    },
  });

export default makeStyles(incomeSwitchInputStyles);
