import React, { FC } from "react";
import { Route as ReactRoute, Switch, withRouter } from "react-router-dom";
import { Route } from "./routes";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "./containers/auth/auth.redux";
import GuestHomepage from "./containers/homepage/guest/GuestHomepage";
import UserHomepage from "./containers/homepage/user/UserHomepage";
import Transactions from "./containers/transactions/TransactionsContainer";
import Recurring from "./containers/recurring/RecurringContainer";
import Imprint from "./containers/legal/Imprint";
import Statistics from "./containers/statistics/Statistics";
import Settings from "./containers/settings/Settings";
import NoRoute from "./containers/errors/NoRoute";
import NoAuth from "./containers/errors/NoAuth";

const AppRoutes: FC = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <Switch>
      <ReactRoute
        exact
        path={Route.ROOT}
        component={isLoggedIn ? UserHomepage : GuestHomepage}
      />
      <ReactRoute
        exact
        path={Route.TRANSACTIONS}
        component={isLoggedIn ? Transactions : NoAuth}
      />
      <ReactRoute
        exact
        path={Route.STATISTICS}
        component={isLoggedIn ? Statistics : NoAuth}
      />
      <ReactRoute
        exact
        path={Route.RECURRING}
        component={isLoggedIn ? Recurring : NoAuth}
      />
      <ReactRoute
        exact
        path={Route.SETTINGS}
        component={isLoggedIn ? Settings : NoAuth}
      />
      <ReactRoute exact path={Route.IMPRINT} component={Imprint} />
      <ReactRoute component={NoRoute} />
    </Switch>
  );
};

export default withRouter(AppRoutes);
