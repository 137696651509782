import {
  createAction,
  withInitialState,
  onAction,
  chainReducers,
} from "redux-preboiled";
import { Recurring } from "../../models/Recurring.model";

export interface RecurringState {
  isGettingRecurring: boolean;
  recurring: Recurring[];
}

export interface State {
  recurring: RecurringState;
}

const initialState: RecurringState = {
  isGettingRecurring: false,
  recurring: [],
};

export const selectIsGettingRecurring = (state: State) =>
  state.recurring.isGettingRecurring;

export const selectRecurring = (state: State) => state.recurring.recurring;

export const gettingRecurringAction = createAction(
  "recurring/gettingRecurring"
);

export const createdRecurringAction = createAction(
  "recurring/createdRecurring"
).withPayload<{ recurring: Recurring }>();

export const gettingRecurringSuccessfulAction = createAction(
  "recurring/gettingRecurringSuccessful"
).withPayload<{ recurring: Recurring[] }>();

export const gettingRecurringFailedAction = createAction(
  "recurring/gettingRecurringFailed"
).withPayload<{ errMsg: string }>();

export default chainReducers(
  withInitialState(initialState),
  onAction(createdRecurringAction, (state, { payload: { recurring } }) => ({
    ...state,
    recurring: [...state.recurring, recurring],
  })),
  onAction(gettingRecurringAction, (state) => ({
    ...state,
    isGettingRecurring: true,
  })),
  onAction(gettingRecurringFailedAction, (state) => ({
    ...state,
    isGettingRecurring: false,
  })),
  onAction(
    gettingRecurringSuccessfulAction,
    (state, { payload: { recurring } }) => ({
      ...state,
      isGettingRecurring: false,
      recurring: [...recurring],
    })
  )
);
