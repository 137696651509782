export type RecurringCategoryType = {
  value: RecurringCategory;
  text: string;
};

export type TransactionCategoryType = {
  value: TransactionCategory;
  text: string;
};

// Sync with BE
export enum RecurringCategory {
  _TBD = "_TBD",
  ALIMONY = "ALIMONY",
  BENEFIT = "BENEFIT",
  COMMUNICATION = "COMMUNICATION",
  ENTERTAINMENT = "ENTERTAINMENT",
  HOUSEHOLD = "HOUSEHOLD",
  INSURANCE = "INSURANCE",
  LEVY = "LEVY",
  MAINTENANCE = "MAINTENANCE",
  MEMBERSHIP = "MEMBERSHIP",
  OTHER = "OTHER",
  RENT = "RENT",
  REAL_ESTATE = "REAL_ESTATE",
  SALARY = "SALARY",
  SUBSCRIPTION = "SUBSCRIPTION",
  TRANSPORTATION = "TRANSPORTATION",
  UTILITY = "UTILITY",
}

// _TBD always has to be first, OTHER always last
export const RecurringCategories: RecurringCategoryType[] = [
  { value: RecurringCategory._TBD, text: "recurring.categories._tbd" },
  { value: RecurringCategory.ALIMONY, text: "recurring.categories.alimony" },
  { value: RecurringCategory.BENEFIT, text: "recurring.categories.benefit" },
  {
    value: RecurringCategory.COMMUNICATION,
    text: "recurring.categories.communication",
  },
  {
    value: RecurringCategory.ENTERTAINMENT,
    text: "recurring.categories.entertainment",
  },
  {
    value: RecurringCategory.HOUSEHOLD,
    text: "recurring.categories.household",
  },
  {
    value: RecurringCategory.INSURANCE,
    text: "recurring.categories.insurance",
  },
  { value: RecurringCategory.LEVY, text: "recurring.categories.levy" },
  {
    value: RecurringCategory.MAINTENANCE,
    text: "recurring.categories.maintenance",
  },
  {
    value: RecurringCategory.MEMBERSHIP,
    text: "recurring.categories.membership",
  },
  {
    value: RecurringCategory.REAL_ESTATE,
    text: "recurring.categories.realEstate",
  },
  {
    value: RecurringCategory.RENT,
    text: "recurring.categories.rent",
  },
  { value: RecurringCategory.SALARY, text: "recurring.categories.salary" },
  {
    value: RecurringCategory.SUBSCRIPTION,
    text: "recurring.categories.subscription",
  },
  {
    value: RecurringCategory.TRANSPORTATION,
    text: "recurring.categories.transportation",
  },
  { value: RecurringCategory.UTILITY, text: "recurring.categories.utility" },
  { value: RecurringCategory.OTHER, text: "recurring.categories.other" },
];

export enum TransactionCategory {
  _TDB = "_TBD",
  CLOTHING = "CLOTHING",
  ELECTRONICS = "ELECTRONICS",
  ENTERTAINMENT = "ENTERTAINMENT",
  FOOD = "FOOD",
  GROCERIES = "GROCERIES",
  HOUSEHOLD = "HOUSEHOLD",
  LUXURY = "LUXURY",
  MATERIALS_AND_TOOLS = "MATERIALS_AND_TOOLS",
  OTHER = "OTHER",
  SOFTWARE = "SOFTWARE",
  TRANSPORTATION = "TRANSPORTATION",
}

// _TBD always has to be first, OTHER always last
export const TransactionCategories: TransactionCategoryType[] = [
  { value: TransactionCategory._TDB, text: "transaction.categories._tbd" },
  {
    value: TransactionCategory.CLOTHING,
    text: "transaction.categories.clothing",
  },
  {
    value: TransactionCategory.ELECTRONICS,
    text: "transaction.categories.electronics",
  },
  {
    value: TransactionCategory.ENTERTAINMENT,
    text: "transaction.categories.entertainment",
  },
  {
    value: TransactionCategory.FOOD,
    text: "transaction.categories.food",
  },
  {
    value: TransactionCategory.GROCERIES,
    text: "transaction.categories.groceries",
  },
  {
    value: TransactionCategory.HOUSEHOLD,
    text: "transaction.categories.household",
  },
  { value: TransactionCategory.LUXURY, text: "transaction.categories.luxury" },
  {
    value: TransactionCategory.MATERIALS_AND_TOOLS,
    text: "transaction.categories.materialsAndTools",
  },
  {
    value: TransactionCategory.SOFTWARE,
    text: "transaction.categories.software",
  },
  {
    value: TransactionCategory.TRANSPORTATION,
    text: "transaction.categories.transportation",
  },
  // { value: TransactionCategory._TBD, text: "transaction.categories._tbd" },
  { value: TransactionCategory.OTHER, text: "transaction.categories.other" },
];
