import React, { FC, useEffect, useMemo } from "react";
import recurringStyle from "./recurring.style";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Divider } from "@material-ui/core";
import CreateRecurringForm from "../../forms/recurring/CreateRecurringForm";
import { useDispatch, useSelector } from "react-redux";
import {
  gettingRecurringAction,
  gettingRecurringSuccessfulAction,
  gettingRecurringFailedAction,
  selectRecurring,
  selectIsGettingRecurring,
} from "./recurring.redux";
import { useRecurringService } from "./recurring.service";
import { useSnackbar } from "notistack";
import { Atoms } from "../../atoms/Atoms";
import RecurringList from "./lists/RecurringList";
import { getAbsDailyImpact } from "../../utils/interval";

const RecurringContainer: FC = () => {
  const classes = recurringStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getRecurring, getRecurringImpacts } = useRecurringService();
  const snackbar = useSnackbar();
  const unsortedRecurring = useSelector(selectRecurring);
  const isGettingRecurring = useSelector(selectIsGettingRecurring);

  useEffect(
    () => {
      getRecurringImpacts()
        .then((result) => console.log("result: ", result))
        .catch((err) => console.log("err: ", err));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    dispatch(gettingRecurringAction());
    getRecurring()
      .then((recurring) => {
        dispatch(gettingRecurringSuccessfulAction({ recurring }));
      })
      .catch((err) => {
        snackbar.enqueueSnackbar(t("notification.recurring.fetchError"), {
          variant: "error",
        });
        dispatch(gettingRecurringFailedAction(err.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recurring = useMemo(() => {
    if (!isGettingRecurring) {
      return unsortedRecurring.sort(
        (a, b) => getAbsDailyImpact(b) - getAbsDailyImpact(a)
      );
    }
    return [];
  }, [isGettingRecurring, unsortedRecurring]);

  return (
    <>
      <Grid container component="div" spacing={3}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Paper className={classes.paper}>
            <Atoms.PaperHeader>
              {t("recurring.createForm.title")}
            </Atoms.PaperHeader>
            <Divider className={classes.divider} />
            <CreateRecurringForm classes={classes} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={8} xl={9}>
          <Paper className={classes.paper}>
            <Atoms.PaperHeader>
              {t("recurring.list.title")}
              {isGettingRecurring && <Atoms.CircularProgress />}
            </Atoms.PaperHeader>
            <Divider className={classes.divider} />
            <RecurringList classes={classes} recurring={recurring} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default RecurringContainer;
