import {
  Transaction,
  TransactionCreationForm,
  TransactionCreationRequestPayload,
  TransactionDeletionProps,
  SingleTransactionResponse,
  ManyTransactionResponse,
  TransactionQueryParams,
} from "../../models/Transaction.model";
import { i18nStrToNum } from "../../utils/numbers";
import { useApi } from "../../hooks/api";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useTransactionService = () => {
  const { request } = useApi();
  const { t } = useTranslation();

  return {
    createTransaction: useCallback(
      ({
        isIncome,
        amount,
        currency,
        date,
        writeOff,
        description,
        category,
      }: TransactionCreationForm): Promise<Transaction> => {
        const absolutePayloadAmount = i18nStrToNum(amount);

        if (isNaN(absolutePayloadAmount)) {
          return Promise.reject(t("transaction.createForm.errors.amountNaN"));
        }

        // get the YYYY-MM-DD from the isoDate
        const isoDate = date.toISOString().substr(0, 10);

        let signedPayloadAmount = isIncome
          ? absolutePayloadAmount
          : -1 * absolutePayloadAmount;

        const payload: TransactionCreationRequestPayload = {
          amount: signedPayloadAmount,
          date: isoDate,
          writeOff: writeOff || 0,
          category,
          currency,
          description,
        };

        return request<SingleTransactionResponse>({
          url: "transactions",
          method: "POST",
          data: payload,
        }).then(({ transaction }) => transaction);
      },
      [request, t]
    ),

    getTransactions: useCallback(
      (params: TransactionQueryParams): Promise<Transaction[]> => {
        return request<ManyTransactionResponse>({
          url: "transactions",
          params,
          method: "GET",
        }).then(({ transactions }) => transactions);
      },
      [request]
    ),

    deleteTransaction: useCallback(
      ({ createdTime }: TransactionDeletionProps): Promise<Transaction> => {
        return request<SingleTransactionResponse>({
          url: `transactions/createdTime/${createdTime}`,
          method: "DELETE",
        }).then(({ transaction }) => transaction);
      },
      [request]
    ),
  };
};
