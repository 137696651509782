import { Transaction } from "../../models/Transaction.model";
import {
  createAction,
  withInitialState,
  onAction,
  chainReducers,
} from "redux-preboiled";

export interface TransactionState {
  isGettingTransactions: boolean;
  transactions: Transaction[];
}

export interface State {
  transaction: TransactionState;
}

const initialState: TransactionState = {
  isGettingTransactions: false,
  transactions: [],
};

export const selectIsGettingTransactions = (state: State) =>
  state.transaction.isGettingTransactions;

export const selectTransactions = (state: State) =>
  state.transaction.transactions;

export const gettingTransactionsAction = createAction(
  "transaction/gettingTransactions"
);

export const deletingTransactionSuccessfulAction = createAction(
  "transaction/deletingTransactionSuccessful"
).withPayload<{ transaction: Transaction }>();

export const createdTransactionAction = createAction(
  "transaction/createdTransaction"
).withPayload<{ transaction: Transaction }>();

export const gettingTransactionsSuccessfulAction = createAction(
  "transaction/gettingTransactionsSuccessful"
).withPayload<{ transactions: Transaction[] }>();

export const gettingTransactionsFailedAction = createAction(
  "transaction/gettingTransactionsFailed"
).withPayload<{ errMsg: string }>();

export default chainReducers(
  withInitialState(initialState),
  onAction(createdTransactionAction, (state, { payload: { transaction } }) => ({
    ...state,
    transactions: [transaction, ...state.transactions],
  })),
  onAction(gettingTransactionsAction, (state) => ({
    ...state,
    isGettingTransactions: true,
  })),
  onAction(gettingTransactionsFailedAction, (state) => ({
    ...state,
    isGettingTransactions: false,
  })),
  onAction(
    gettingTransactionsSuccessfulAction,
    (state, { payload: { transactions } }) => ({
      ...state,
      isGettingTransactions: false,
      transactions: [...transactions],
    })
  ),
  onAction(
    deletingTransactionSuccessfulAction,
    (state, { payload: { transaction } }) => ({
      ...state,
      transactions: [
        ...state.transactions.filter(
          (cacheTransaction) =>
            transaction.createdTime !== cacheTransaction.createdTime
        ),
      ],
    })
  )
);
