import React, { FC } from "react";
import { Formik, Form, Field } from "formik";
import {
  Button,
  LinearProgress,
  DialogTitle,
  DialogContent,
  Dialog,
  Link as MuiLink,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { TextField } from "formik-material-ui";
import { AuthUser } from "../../../models/Auth.model";
import { useSelector, useDispatch } from "react-redux";
import {
  selectShowLoginModal,
  toggleSignupModalAction,
  toggleLoginModalAction,
} from "../../../redux/modals.redux";
import {
  selectLoginError,
  clearErrorMessagesAction,
  selectIsAuthInProgress,
} from "../auth.redux";
import AuthStyles from "../auth.style";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
interface Values extends AuthUser {}

type Props = {
  loginUser: (authUser: AuthUser) => any;
};

const initialValues: Values = {
  email: "",
  password: "",
};

const Login: FC<Props> = ({ loginUser }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showLogin = useSelector(selectShowLoginModal);
  const loginError = useSelector(selectLoginError);

  const isSubmitting = useSelector(selectIsAuthInProgress);

  const authStyles = AuthStyles();

  const onClose = () => {
    dispatch(clearErrorMessagesAction());
    dispatch(toggleLoginModalAction({ show: false }));
  };

  return (
    <Dialog
      open={showLogin}
      onClose={onClose}
      aria-labelledby="login-dialog"
      className={authStyles.dialog}
    >
      <DialogTitle id="login-dialog" className={authStyles.dialogTitle}>
        {t("auth.login.modal.title")}
      </DialogTitle>
      <DialogContent className={authStyles.dialogContent}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => !isSubmitting && loginUser(values)}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("auth.login.errors.notEmail"))
              .required(t("auth.login.errors.emailRequired")),
            password: Yup.string().required(
              t("auth.login.errors.passwordRequired")
            ),
          })}
        >
          {({ submitForm }) => (
            <Form className={authStyles.form}>
              <Field
                component={TextField}
                name="email"
                type="email"
                label={t("auth.login.modal.email")}
                fullWidth
                className={authStyles.textField}
                disabled={isSubmitting}
              />
              <br />
              <Field
                component={TextField}
                type="password"
                label={t("auth.login.modal.password")}
                name="password"
                fullWidth
                className={authStyles.textField}
                disabled={isSubmitting}
              />
              {isSubmitting && <br /> && <LinearProgress />}
              <br />
              {loginError && (
                <Alert severity="error" className={authStyles.alert}>
                  {loginError.toString()}
                </Alert>
              )}
              <div className={authStyles.centerDiv}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  className={authStyles.submit}
                >
                  {t("auth.login.modal.submit")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div className={authStyles.centerDiv}>
          <div className={authStyles.noAccountText}>
            {t("auth.login.modal.noAccount")}{" "}
            <MuiLink
              component="button"
              variant="body1"
              onClick={() => {
                dispatch(toggleSignupModalAction({ show: true }));
              }}
            >
              {t("auth.login.modal.registerAccountLink")}
            </MuiLink>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
