import { useApi } from "../../hooks/api";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { i18nStrToNum } from "../../utils/numbers";
import {
  RecurringCreationForm,
  Recurring,
  RecurringCreationRequest,
  SingleRecurringResponse,
  ManyRecurringResponse,
} from "../../models/Recurring.model";
export const useRecurringService = () => {
  const { request } = useApi();
  const { t } = useTranslation();

  return {
    createRecurring: useCallback(
      ({
        amountPerInterval,
        endDate,
        hasEndDate,
        isIncome,
        startDate,
        ...rest
      }: RecurringCreationForm): Promise<Recurring> => {
        const absolutePayloadAmount = i18nStrToNum(amountPerInterval);

        if (isNaN(absolutePayloadAmount)) {
          return Promise.reject(t("transaction.createForm.errors.amountNaN")); // TODO
        }

        let signedPayloadAmount = isIncome
          ? absolutePayloadAmount
          : -1 * absolutePayloadAmount;

        const payload: RecurringCreationRequest = {
          amountPerInterval: signedPayloadAmount,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: hasEndDate ? endDate!.format("YYYY-MM-DD") : null,
          ...rest,
        };

        return request<SingleRecurringResponse>({
          url: "recurring",
          method: "POST",
          data: payload,
        }).then(({ recurring }) => recurring);
      },
      [request, t]
    ),

    getRecurring: useCallback((): Promise<Recurring[]> => {
      return request<ManyRecurringResponse>({
        url: "recurring",
        method: "GET",
      }).then(({ recurring }) => recurring);
    }, [request]),

    getRecurringImpacts: useCallback((): Promise<Recurring[]> => {
      return request<any>({
        url: "recurringImpacts",
        method: "GET",
        params: {
          month: "2020-08",
        },
      }).then(({ impact }) => impact);
    }, [request]),

    /**
     *
     * @param date - expected in YYYY-MM-DD format
     */
    getRecurringForDate: useCallback(
      (date: string): Promise<Recurring[]> => {
        return request<ManyRecurringResponse>({
          url: `recurring/active/${date}`,
          method: "GET",
        }).then(({ recurring }) => recurring);
      },
      [request]
    ),

    getRecurringById: useCallback(
      (id: string): Promise<Recurring> => {
        return request<SingleRecurringResponse>({
          url: `recurring/id/${id}`,
          method: "GET",
        }).then(({ recurring }) => recurring);
      },
      [request]
    ),
  };
};
