import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { Field } from "formik";
import { SwitchProps, Switch } from "formik-material-ui";
import incomeSwitchInputStyle from "./incomeSwitchInput.style";

interface Props extends Partial<SwitchProps> {
  classes: Record<string, string>;
}

const IncomeSwitchInput: FC<Props> = ({
  classes: inputClasses,
  form,
  field,
  color,
  disabled,
}) => {
  const ownClasses = incomeSwitchInputStyle();
  const classes: Record<string, string> = useMemo(
    () => ({ ...ownClasses, ...inputClasses }),
    [ownClasses, inputClasses]
  );
  const { t } = useTranslation();
  return (
    <Grid component="label" item container spacing={1} justify="center">
      <Grid item>
        <span
          className={clsx(classes.expense, {
            [classes.highlightExpense]: !form?.values.isIncome,
          })}
        >
          {t("form.component.input.incomeSwitch.labels.expense")}
        </span>
      </Grid>
      <Field
        component={Switch}
        type="checkbox"
        name={field?.name}
        color={color || "primary"}
        onBlur={field?.onBlur}
        onChange={field?.onChange}
        disabled={disabled || form?.isSubmitting}
      />
      <Grid item>
        <span
          className={clsx(classes.earning, {
            [classes.highlightEarning]: form?.values.isIncome,
          })}
        >
          {t("form.component.input.incomeSwitch.labels.earning")}
        </span>
      </Grid>
    </Grid>
  );
};

export default IncomeSwitchInput;
