import {
  ICognitoUserPoolData,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { loadedState } from "./LocalStorage";
import { getPreferredLanguage } from "./utils/browser";

const poolData: ICognitoUserPoolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "",
};

export const userPool = new CognitoUserPool(poolData);

export const browserLang =
  loadedState?.config?.language || getPreferredLanguage();

const origin: string = window.location.origin;

const devUrl = "https://fbud-dev.douglas-reiser.de";

export const baseUrl = origin.includes("localhost") ? devUrl : origin;

export const apiUrl = baseUrl + "/api/";
